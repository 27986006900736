<ng-container *ngIf="true">
  <div dougsModalTitle>
    <h6>Attacher votre pièce justificative</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <dougs-avatar-message
      size="medium"
      class="mb-16"
      [fullName]="
        attachProofAddressModalService.data.task?.assignee?.profile | avatarName: 'David Bernier, Responsable Juridique'
      "
      [avatarUrl]="
        attachProofAddressModalService.data.task?.assignee?.profile | avatarUrl: 'images/avatar/small-david.jpg'
      "
    >
      <p class="small mb-8">Bonjour {{ attachProofAddressModalService.data.task.target.profile.firstName }},</p>
      <p class="small mb-8">
        Nous avons besoin d'un justificatif de domiciliation de votre société datant de moins de 3 mois pour compléter
        votre dossier.
      </p>
      <p class="small mb-8">
        Les documents acceptés sont les suivants : facture d'électricité, facture de téléphone fixe (pas mobile),
        facture d'internet, quittance de loyer, bail commercial, contrat de domiciliation.
      </p>
      <p class="small mb-8">Je reste disponible, via le tchat, pour toute question complémentaire.</p>
    </dougs-avatar-message>
    <div class="ml-40 mt-16">
      <h6 class="mb-8">
        Justificatif de domiciliation de la société
        <span class="file-format">(format accepté : pdf, jpg, png)</span>
      </h6>

      <div class="file-list">
        <dougs-file-input
          [square]="true"
          [multiple]="false"
          [acceptFileType]="'application/pdf, image/jpeg, image/png'"
          (uploadFiles)="attachProofAddressModalService.onUploadFiles($event)"
        ></dougs-file-input>

        <ng-container *ngIf="attachProofAddressModalService.proofAddressAttachment as attachment">
          <dougs-file-pill
            [square]="true"
            [attachment]="attachment"
            (deleteFile)="attachProofAddressModalService.onDeleteFile($event)"
          ></dougs-file-pill>
        </ng-container>
      </div>
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Plus tard</dougs-button>
    <dougs-button
      [disabled]="!attachProofAddressModalService.proofAddressAttachment"
      (click)="attachProofAddressModalService.confirmProofAddressAttachment()"
    >
      <i class="fal fa-check mr-4"></i>
      Valider
    </dougs-button>
  </div>
</ng-container>
