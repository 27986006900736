import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { FieldsStateService } from '@dougs/fields/shared';
import { CapitalDepositStateService } from '@dougs/legal/shared';
import { Task } from '@dougs/task/dto';

@Injectable()
export class ProjectValidationModalService {
  previewUri$: Observable<SafeResourceUrl | undefined> = this.capitalDepositStateService.capitalDeposit$.pipe(
    map((capitalDeposit) => {
      const fileUrl = capitalDeposit?.creationTask?.attachments?.find(
        (attachement) => attachement.type === 'legal:creationProject' && attachement.metadata?.isProjectStatusDocument,
      )?.file.url;

      return fileUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(`${fileUrl}`) : undefined;
    }),
  );

  statusProjectDate$: Observable<Date | undefined> = this.capitalDepositStateService.capitalDeposit$.pipe(
    map((capitalDeposit) => {
      const date = capitalDeposit?.creationTask?.attachments.find(
        (attachement) => attachement.type === 'legal:creationProject',
      )?.createdAt;

      return date;
    }),
  );

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
      personInCharge: { avatarUrl: string; name: string };
    },
    private readonly modalRef: ModalRef,
    private readonly sanitizer: DomSanitizer,
    public readonly fieldsStateService: FieldsStateService,
    public companyStateService: CompanyStateService,
    private readonly capitalDepositStateService: CapitalDepositStateService,
  ) {}

  async confirm(): Promise<void> {
    this.modalRef.close(this.data.task);
  }
}
