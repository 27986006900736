<ng-container *ngIf="partnershipCapitalDepositModalService.refreshCapitalDeposit$ | async"></ng-container>
<ng-container *ngIf="partnershipCapitalDepositModalService.capitalDeposit$ | async"></ng-container>

<div dougsModalTitle>
  <h6>Déposer mon capital avec {{ partnershipCapitalDepositModalService.capitalDeposit.partner | titlecase }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>

<div dougsModalContent>
  <div class="content">
    <div class="content__message px-8">
      <dougs-avatar-message
        size="medium"
        class="mb-16"
        [fullName]="
          partnershipCapitalDepositModalService.data.personInCharge | avatarName: 'David Bernier, Responsable Juridique'
        "
        [avatarUrl]="
          partnershipCapitalDepositModalService.data.personInCharge | avatarUrl: 'images/avatar/small-david.jpg'
        "
      >
        <p class="small mb-8">
          Bonjour {{ partnershipCapitalDepositModalService.data.task.target.profile?.firstName }},
        </p>
        <p class="small mb-8">
          Votre projet de statuts est validé ! Vous pouvez désormais déposer votre capital social en quelques clics
          grâce à notre partenaire {{ partnershipCapitalDepositModalService.capitalDeposit.partner | titlecase }} en
          suivant le lien ci-dessous. Nous communiquerons pour vous, l'ensemble des documents nécessaires à
          {{ partnershipCapitalDepositModalService.capitalDeposit.partner | titlecase }}. Dès validation du dépôt,
          {{ partnershipCapitalDepositModalService.capitalDeposit.partner | titlecase }} nous transmettra l'attestation
          de dépôt pour continuer votre création.
        </p>
        <p class="small">Je reste disponible, via le tchat, pour toute question complémentaire.</p>
        <div class="file-list">
          <dougs-file-pill
            *ngIf="partnershipCapitalDepositModalService.capitalDepositCertificateAttachment$ | async as attachment"
            [canDelete]="false"
            [attachment]="attachment"
          ></dougs-file-pill>
        </div>
      </dougs-avatar-message>
    </div>

    <div class="mt-24 deposit-container">
      <div>
        <dougs-button (click)="partnershipCapitalDepositModalService.accessToCapitalDeposit()">
          {{ partnershipCapitalDepositModalService.capitalDepositButtonText }}
          <i class="fal fa-arrow-right ml-8"></i>
        </dougs-button>
      </div>
    </div>

    <div class="mt-24">
      <p class="small">
        {{ partnershipCapitalDepositModalService.capitalDeposit.partner | titlecase }} nous transmet directement votre
        attestation de dépôt de capital.
      </p>
      <p class="small mb-8">
        En cas d’erreur, de refus ou si toutefois vous passez par une autre banque, veuillez simplement nous joindre
        votre attestation :
      </p>

      <div class="file-list">
        <dougs-file-input
          [square]="true"
          (uploadFiles)="partnershipCapitalDepositModalService.onUploadTaskFiles($event)"
        ></dougs-file-input>
        <dougs-file-pill
          [square]="true"
          *ngIf="
            partnershipCapitalDepositModalService.capitalDepositCertificateAttachment$
              | async as capitalDepositCertificateAttachment
          "
          (deleteFile)="
            partnershipCapitalDepositModalService.removeTaskAttachment(
              partnershipCapitalDepositModalService.capitalDepositTask,
              capitalDepositCertificateAttachment
            )
          "
          [attachment]="capitalDepositCertificateAttachment"
        ></dougs-file-pill>
      </div>
    </div>
  </div>
</div>

<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
  <dougs-button
    [disabled]="!(partnershipCapitalDepositModalService.capitalDepositCertificateAttachment$ | async)"
    (click)="partnershipCapitalDepositModalService.confirm()"
  >
    Valider
  </dougs-button>
</div>
