<ng-container *ngIf="businessAssetsSaleModalService.task">
  <div dougsModalTitle>
    <h6>Attacher votre acte de cession enregistré par le SIE dans Dougs</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <dougs-avatar-message
      size="medium"
      class="mb-16"
      [fullName]="businessAssetsSaleModalService.collaboratorName"
      [avatarUrl]="businessAssetsSaleModalService.avatarUrl"
    >
      <p class="small mb-8">Bonjour {{ businessAssetsSaleModalService.task.assignee.profile?.firstName }},</p>

      <p class="small mb-8">
        Pour finaliser votre prestation de cession de fonds de commerce, veuillez joindre ci-dessous votre acte de
        cession signé par votre SIE.
      </p>

      <p class="small mb-8">Je reste disponible, via le tchat, pour toute question complémentaire.</p>
    </dougs-avatar-message>
    <div class="ml-40 mt-16">
      <h6 class="mb-8">Attacher ici mon acte de cession enregistré par le SIE :</h6>
      <div class="file-list">
        <dougs-file-input (uploadFiles)="businessAssetsSaleModalService.onUploadTaskFiles($event)"></dougs-file-input>
        <dougs-file-pill
          (deleteFile)="controlPanelTasksStateService.removeTaskAttachment(businessAssetsSaleModalService.task, $event)"
          *ngFor="
            let attachment of businessAssetsSaleModalService.saleDeedAttachments$ | async;
            trackBy: 'id' | trackBy
          "
          [attachment]="attachment"
        ></dougs-file-pill>
      </div>
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Plus tard</dougs-button>
    <dougs-button
      type="button"
      color="primary"
      [disabled]="!(businessAssetsSaleModalService.saleDeedAttachments$ | async).length"
      (click)="businessAssetsSaleModalService.confirm()"
    >
      <i class="fal fa-check mr-4"></i>
      Valider
    </dougs-button>
  </div>
</ng-container>
