import { Inject, Injectable } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { generateUuidV4 } from '@dougs/core/utils';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { AllFields, Field } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { FileStateService } from '@dougs/settings/shared';
import { Task } from '@dougs/task/dto';

@Injectable()
export class AttachProofAddressModalService {
  proofAddressAttachment?: Attachment;
  private file?: File;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
    },
    private readonly fileStateService: FileStateService,
    private readonly fieldsStateService: FieldsStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalRef: ModalRef,
  ) {}

  onUploadFiles(file: File): void {
    this.file = file;

    if (file) {
      this.proofAddressAttachment = {
        id: generateUuidV4(),
        file: {
          url: URL.createObjectURL(file),
        },
        modelId: 0,
        companyId: 0,
        name: file.name,
      };
    }
  }

  onDeleteFile(): void {
    this.proofAddressAttachment = undefined;
  }

  private async getProofOfAddressField(): Promise<Field> {
    const company: Company = this.companyStateService.activeCompany;
    const allFields: AllFields = await this.fieldsStateService.getCompanyFieldsByGroupIds(company, ['legal']);
    return allFields.company['proofOfAddress'];
  }

  async confirmProofAddressAttachment(): Promise<void> {
    const company: Company = this.companyStateService.activeCompany;
    const proofOfAddressField: Field = await this.getProofOfAddressField();
    if (proofOfAddressField?.input.fileType && this.file) {
      await this.fileStateService.uploadFiles(
        company,
        proofOfAddressField?.input.fileType,
        'proofOfAddress',
        this.file,
      );
      await this.companyStateService.refreshCompany();
      await this.fieldsStateService.refreshCompanyFields(company);
      this.modalRef.close();
    }
  }
}
