import { Inject, Injectable } from '@angular/core';
import { concatMap, map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { CapitalDeposit, CapitalDepositStateService } from '@dougs/legal/shared';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService, UserTasksStateService } from '@dougs/task/shared';

@Injectable()
export class CapitalDepositModalService {
  capitalDeposit!: CapitalDeposit;
  task!: Task | undefined;
  creationProjectAttachments: Attachment[] = [];
  capitalDepositCertificateAttachments: Attachment[] = [];
  isUploadedFile = false;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
      personInCharge: { avatarUrl: string; name: string };
    },
    private readonly companyStateService: CompanyStateService,
    private readonly capitalDepositStateService: CapitalDepositStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly modalRef: ModalRef,
  ) {}

  refreshCapitalDeposit$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((company) => this.capitalDepositStateService.refreshCapitalDeposit(company)),
  );

  capitalDeposit$: Observable<void> = this.capitalDepositStateService.capitalDeposit$.pipe(
    map((capitalDeposit) => {
      this.capitalDeposit = capitalDeposit;
    }),
  );

  initUserTasks$: Observable<void> = this.userTasksStateService.tasks$.pipe(
    map((tasks) => {
      this.task = tasks.find((task) => task.id === this.data.task.id);
      if (this.task) {
        this.creationProjectAttachments = this.task?.attachments.filter(
          (attachment) => attachment.type === 'legal:creationProject',
        );
        this.capitalDepositCertificateAttachments = this.task?.attachments.filter(
          (attachment) => attachment.type === 'legal:capitalDepositCertificate',
        );
      }
    }),
  );

  async onUploadTaskFiles(files: FileList): Promise<void> {
    if (this.task) {
      await this.controlPanelTasksStateService.uploadTaskAttachments(this.task, Array.from(files), {
        fileType: 'legal:capitalDepositCertificate',
      });
    }
  }

  confirm(task: Task): void {
    if (task.attachments.length) {
      this.isUploadedFile = true;
      this.modalRef.close(task);
    }
    this.isUploadedFile = false;
  }
}
