<ng-container *ngIf="capitalDepositModalService.refreshCapitalDeposit$ | async"></ng-container>
<ng-container *ngIf="capitalDepositModalService.capitalDeposit$ | async"></ng-container>
<ng-container *ngIf="capitalDepositModalService.initUserTasks$ | async"></ng-container>

<ng-container *ngIf="capitalDepositModalService.task">
  <div dougsModalTitle>
    <h6>Attachez votre attestation de dépôt de capital</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <dougs-avatar-message
      size="medium"
      class="mb-16"
      [fullName]="capitalDepositModalService.data.personInCharge | avatarName: 'David Bernier, Responsable Juridique'"
      [avatarUrl]="capitalDepositModalService.data.personInCharge | avatarUrl: 'images/avatar/small-david.jpg'"
    >
      <p class="small mb-8">Bonjour {{ capitalDepositModalService.data.task.assignee.profile?.firstName }},</p>

      <p class="small mb-8">
        Votre projet de statuts est prêt ! Vous pouvez désormais déposer votre capital social auprès de votre banque.
        Celle-ci vous transmettra une attestation de dépôt qu'il vous suffira d'attacher grâce au bouton ci-dessous.
      </p>

      <p class="small mb-8">Je reste disponible, via le tchat, pour toute question complémentaire.</p>
      <div class="file-list">
        <dougs-file-pill
          [canDelete]="false"
          *ngFor="let attachment of capitalDepositModalService.creationProjectAttachments; trackBy: 'id' | trackBy"
          [attachment]="attachment"
        ></dougs-file-pill>
      </div>
    </dougs-avatar-message>
    <div class="ml-40 mt-16">
      <h6 class="mb-8">Attachez votre attestation de dépôt de capital :</h6>
      <div class="file-list">
        <dougs-file-input
          [square]="true"
          (uploadFiles)="capitalDepositModalService.onUploadTaskFiles($event)"
          fileInputText="Attacher mon attestation"
        ></dougs-file-input>
        <dougs-file-pill
          [square]="true"
          (deleteFile)="controlPanelTasksStateService.removeTaskAttachment(capitalDepositModalService.task, $event)"
          *ngFor="
            let attachment of capitalDepositModalService.capitalDepositCertificateAttachments;
            trackBy: 'id' | trackBy
          "
          [attachment]="attachment"
        ></dougs-file-pill>
      </div>
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Plus tard</dougs-button>
    <dougs-button
      type="button"
      color="primary"
      [disabled]="!capitalDepositModalService.capitalDepositCertificateAttachments.length"
      (click)="capitalDepositModalService.confirm(capitalDepositModalService.task)"
    >
      <i class="fal fa-check mr-4"></i>
      Valider
    </dougs-button>
  </div>
</ng-container>
