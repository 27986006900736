import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResponsiveService } from '@dougs/core/responsive';
import {
  AvatarMessageComponent,
  AvatarNamePipe,
  AvatarUrlPipe,
  ButtonComponent,
  DougsDatePipe,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { ProjectValidationModalService } from '../../services/project-validation-modal.service';

@Component({
  selector: 'dougs-project-validation-modal',
  templateUrl: './project-validation-modal.component.html',
  styleUrls: ['./project-validation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ButtonComponent,
    AvatarMessageComponent,
    AvatarNamePipe,
    AvatarUrlPipe,
    DougsDatePipe,
  ],
  providers: [ProjectValidationModalService],
})
export class ProjectValidationModalComponent {
  constructor(
    public readonly projectValidationModalService: ProjectValidationModalService,
    public readonly responsiveService: ResponsiveService,
  ) {}
}
