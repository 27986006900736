import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Attachment } from '@dougs/core/files';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';

@Injectable()
export class BusinessAssetsSaleModalService {
  avatarUrl!: string;
  collaboratorName!: string;
  constructor(
    @Inject(MODAL_DATA)
    public task: Task,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly modalRef: ModalRef,
  ) {
    this.collaboratorName = this.getCollaboratorName(task);
    this.avatarUrl = this.getAvatarUrl(task);
  }

  saleDeedAttachments$: Observable<Attachment[]> = this.userTasksStateService.tasks$.pipe(
    map((tasks: Task[]) => tasks.find((task) => task.id === this.task.id)),
    map(
      (task: Task | undefined) =>
        task?.attachments?.filter((attachment) => attachment.type === 'legal:businessAssetsSale.files.saleDeed') ?? [],
    ),
  );

  async onUploadTaskFiles(files: FileList): Promise<void> {
    if (this.task) {
      await this.userTasksStateService.uploadTaskAttachments(this.task, Array.from(files), {
        fileType: 'legal:businessAssetsSale.files.saleDeed',
      });
    }
  }

  private getAvatarUrl(task: Task): string {
    if (task.metadata.collaborator?.avatarUrl) {
      return task.metadata.collaborator.avatarUrl;
    }
    return 'images/avatar/small-david.jpg';
  }

  private getCollaboratorName(task: Task): string {
    if (task.metadata.collaborator?.fullName) {
      return task.metadata.collaborator.fullName;
    }
    return 'David Bernier, Responsable Juridique';
  }

  confirm(): void {
    this.modalRef.close(this.task);
  }
}
