import { Inject, Injectable } from '@angular/core';
import { concatMap, map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { CapitalDeposit, CapitalDepositStateService } from '@dougs/legal/shared';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService, UserTasksStateService } from '@dougs/task/shared';

@Injectable()
export class PartnershipCapitalDepositModalService {
  capitalDeposit!: CapitalDeposit;
  capitalDepositTask: Task | undefined;
  capitalDepositButtonText = 'Déposer mon capital';

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      task: Task;
      personInCharge: { avatarUrl: string; name: string };
    },
    private readonly companyStateService: CompanyStateService,
    private readonly modalRef: ModalRef,
    private readonly capitalDepositStateService: CapitalDepositStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly window: Window,
  ) {}

  refreshCapitalDeposit$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((company) => this.capitalDepositStateService.refreshCapitalDeposit(company)),
  );

  capitalDeposit$: Observable<void> = this.capitalDepositStateService.capitalDeposit$.pipe(
    map((capitalDeposit) => {
      this.capitalDeposit = capitalDeposit;

      if (this.capitalDeposit.isInProcess) {
        this.capitalDepositButtonText = 'Reprendre mon dépôt de capital';
      } else {
        this.capitalDepositButtonText = 'Déposer mon capital';
      }
    }),
  );

  capitalDepositCertificateAttachment$: Observable<Attachment | undefined> = this.userTasksStateService.tasks$.pipe(
    map((tasks) => {
      this.capitalDepositTask = tasks.find((task) => task.id === this.data.task.id);
      return this.capitalDepositTask?.attachments?.find(
        (attachment) => attachment.type === 'legal:capitalDepositCertificate',
      );
    }),
  );

  async onUploadTaskFiles(files: FileList): Promise<void> {
    if (this.capitalDepositTask) {
      await this.controlPanelTasksStateService.uploadTaskAttachments(this.capitalDepositTask, Array.from(files), {
        fileType: 'legal:capitalDepositCertificate',
      });
    }
  }

  async removeTaskAttachment(task: Task, attachment: Attachment): Promise<void> {
    await this.controlPanelTasksStateService.removeTaskAttachment(task, attachment);
  }

  async accessToCapitalDeposit(): Promise<void> {
    if (this.capitalDeposit.isInProcess) {
      this.openPartnerRedirectionLink();
    } else {
      const res = await this.capitalDepositStateService.startCapitalDepositForCompany(
        this.companyStateService.activeCompany,
      );
      if (res?.isSuccess) {
        await this.userTasksStateService.refreshTasks(this.companyStateService.activeCompany);
        await this.capitalDepositStateService.refreshCapitalDeposit(this.companyStateService.activeCompany);

        if (this.capitalDeposit.isInProcess) {
          this.openPartnerRedirectionLink();
        }
      }
    }
  }

  private openPartnerRedirectionLink(): void {
    this.window.open(this.capitalDeposit.partnerRedirectionLink ?? undefined, '_blank');
  }

  async confirm(): Promise<void> {
    this.modalRef.close(this.data.task);
  }
}
