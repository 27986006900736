import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AvatarMessageComponent,
  AvatarNamePipe,
  AvatarUrlPipe,
  ButtonComponent,
  FileInputComponent,
  FilePillComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  TrackByPipe,
} from '@dougs/ds';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { CapitalDepositModalService } from '../../services/capital-deposit-modal.service';

@Component({
  selector: 'dougs-capital-deposit-modal',
  templateUrl: './capital-deposit-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ButtonComponent,
    AvatarMessageComponent,
    AvatarNamePipe,
    AvatarUrlPipe,
    FileInputComponent,
    FilePillComponent,
    TrackByPipe,
  ],
  providers: [CapitalDepositModalService],
})
export class CapitalDepositModalComponent {
  constructor(
    public readonly capitalDepositModalService: CapitalDepositModalService,
    public readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {}
}
