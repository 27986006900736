import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AvatarMessageComponent,
  AvatarNamePipe,
  AvatarUrlPipe,
  ButtonComponent,
  FileInputComponent,
  FilePillComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { PartnershipCapitalDepositModalService } from '../../services/partnership-capital-deposit-modal.service';

@Component({
  selector: 'dougs-partnership-capital-deposit-modal',
  templateUrl: './partnership-capital-deposit-modal.component.html',
  styleUrls: ['./partnership-capital-deposit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ButtonComponent,
    AvatarMessageComponent,
    AvatarNamePipe,
    AvatarUrlPipe,
    FileInputComponent,
    FilePillComponent,
  ],
  providers: [PartnershipCapitalDepositModalService],
})
export class PartnershipCapitalDepositModalComponent {
  constructor(public readonly partnershipCapitalDepositModalService: PartnershipCapitalDepositModalService) {}
}
