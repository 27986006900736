<div dougsModalTitle>
  <h6>Validation du projet de statuts</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent="large">
  <div class="content">
    <div class="px-8">
      <dougs-avatar-message
        size="medium"
        class="mb-16"
        [fullName]="
          projectValidationModalService.data.personInCharge | avatarName: 'David Bernier, Responsable Juridique'
        "
        [avatarUrl]="projectValidationModalService.data.personInCharge | avatarUrl: 'images/avatar/small-david.jpg'"
      >
        <p class="small mb-8">Bonjour {{ projectValidationModalService.data.task.target.profile?.firstName }},</p>
        <p class="small mb-8">
          Votre projet de statuts en date du
          {{ projectValidationModalService.statusProjectDate$ | async | date: 'dd/MM/yyyy' }} est disponible ! Il ne
          vous reste plus qu'à le valider.
        </p>
        <p class="small mb-8">
          Je reste disponible, via le tchat, pour toute question supplémentaire ou si vous souhaitez apporter des
          modifications à votre projet.
        </p>
      </dougs-avatar-message>
    </div>
    <ng-container *ngIf="projectValidationModalService.previewUri$ | async as previewUri">
      <div *ngIf="!(responsiveService.isMobile | async)">
        <div class="content__iframe px-8" *ngIf="previewUri">
          <iframe class="mb-4" [src]="previewUri"></iframe>
        </div>
      </div>
      <div *ngIf="responsiveService.isMobile | async" class="my-16">
        <dougs-button color="secondary" fullWidth="true">
          <a rel="noopener" [href]="projectValidationModalService.previewUri$ | async" target="_blank">
            <div class="flex-container py-8">
              <i class="fal fa-file"></i>
              <span class="mx-16">Télécharger mon projet de statuts</span>
              <i class="fal fa-chevron-right"></i>
            </div>
          </a>
        </dougs-button>
      </div>
    </ng-container>
  </div>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
  <dougs-button (click)="projectValidationModalService.confirm()">
    <i class="fal fa-check mr-8"></i>
    Valider le projet
  </dougs-button>
</div>
